import React from 'react';
import PageHeader from '../PageHeader';
import styles from './faq.module.scss';

const FAQ = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';

  return (
    <main>
      <PageHeader pageCategory="About" pageName="FAQ" headerImage={background} />
      <div className={styles.textContainer}>
        <h2 className={styles.header}>Frequently Asked Questions</h2>

        <p className={styles.copy}>
          <strong>What can I expect on my first visit?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          The first session is called the assessment session. This is a chance for you to see if we
          are the right therapists for you. We will talk about the reason you are seeking treatment
          and the goals you have. We will ask you questions pertinent to your concerns. We will
          invite you to complete some questionnaires depending on your present situation to get a
          clear picture of how we can help you and get a baseline, so we can monitor your progress.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>Do you offer TeleHealth appointments?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          Yes, we offer TeleHealth appointments in Utah, Idaho, Florida, Michigan, and South
          Carolina. We are actively working on expanding our TeleHealth services to other states
          that allow it.
          <br />
          <br />
          Florida residents can find additional information at the{' '}
          <a
            href="https://flhealthsource.gov/telehealth/"
            target="_blank"
            rel="noopener noreferrer"
          >
            official Florida TeleHealth website
          </a>
          .
          <br />
          <br />
          For clients in Utah, we also offer in-person sessions at our local office.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>Will my health insurance pay for therapy?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          Mindset accepts DMBA (including BYU Student Health), EMI; and Select Health Med, Value,
          Care, Choice, and Share plans. Otherwise, we will be happy to provide you with a
          “Superbill” (a detailed receipt) which has all the information that your insurance company
          will need so that you can be reimbursed. You can submit this receipt to your insurance
          company along with your insurance form to help you get reimbursement for whatever they
          allow. You can also call them and ask if your plan has “out-of-network mental health
          benefits.” We also accept payment from LDS Bishops.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>Do you take credit cards?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          Yes, we accept credit card payments for Visa, Master Card, Discover, and American Express.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>Do you treat children?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          Yes! We have therapists who have been trained in Play Therapy, Child- Parent Relationship
          Therapy, Structural and Attachment theory models.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>What is play therapy?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          Adults’ natural medium of communication is verbalization; for children the natural way of
          communication is through play and activity. A young child uses play as a main source of
          expressing her feelings and thoughts. Play is the child’s language and toys are the
          child’s words. The therapist’s responsibility is to go to the child’s level and
          communicate with children through the medium in which they are most comfortable. Play
          therapy provides the therapist with an opportunity to enter the child’s world.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>What do I do when my therapist is out-of-town or on vacation?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          Your therapist will make arrangements so that you can see another therapist if we
          determine that you need to see someone while she is gone. If we agree that you won’t need
          to see another therapist, and the need to see one arises, you will be given a name and a
          number to call and make arrangements with that particular therapist. However, if you feel
          that you cannot wait, you can call your local ER for clinical emergencies.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>What is your cancellation policy?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          We recognize that unforeseen events occur in daily life. If for any reason you are unable
          to make a scheduled appointment, please notify us as soon as possible. The full fee will
          be charged when there is a missed appointment or a late cancellation without 48 hour prior
          notice.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>What about confidentiality?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          When you come to therapy, our relationship and what we talked about during the session is
          kept strictly confidential. If you wish for us to talk to someone else regarding your
          treatment or your child’s treatment (teacher, doctor, psychiatrist), you will be asked to
          complete an Authorization to Disclose Information form. Confidentiality is maintained
          under all circumstances, except when we believe that you or someone else is at risk if we
          do not break the confidentiality.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>Can I tell my friends and relatives about your services?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          If you are pleased with our services and know someone that may benefit from our services,
          feel free to give them our information so we can determine if we are the right therapists
          for them.
        </p>
        <br />
        <br />
        <br />

        <p className={styles.copy}>
          <strong>How can I reach you?</strong>
        </p>
        <p className={styles.copy}>
          <br />
          You can send an email to: hope@mindsetfamilytherapy.com or you can call us at
          801-427-1054. If we are not available to take your phone call, it is because we are
          helping someone else at that time. Please make sure you leave a clear message with your
          name and phone number even if you think we already have it. Please indicate options of
          times when we can call you back.
        </p>
        <br />
        <br />
        <br />
      </div>
    </main>
  );
};

export default FAQ;
